const cuPlugin = function(editor){
    editor.addCommand('openMediaLib', () => {return;})
    // let self = this
    editor.ui.registry.addButton('myCustomToolbarButton', {
        // text: 'My Custom Button',
        icon:'gallery',
        tooltip: "Media Library",
        onAction: () => {
            // editor._eventDispatcher.fire('mediaLib')
            // editor.fire('mediaLib')
            // console.log(self.$store)
            editor.execCommand('openMediaLib', true);
            // console.log(editor)
            // console.log(editor._eventDispatcher.has('selectionChange'))
        }
        // onAction: () => alert('Button clicked!')
    });
}

export { cuPlugin };