<!-- Modal -->
<template>
    <div class="anime d-flex" :class="mediaLibOpened ? 'col-4 collapse show' : 'collapsed'">
        <div class="tw-create-modal-media-lib-wrapper d-flex w-100" :class="!mediaLibOpened && 'd-none'">
            <div class="row flex-grow-1 px-4 w-100">
                <div class="col tw-light-grey-bg-color p-3 d-flex flex-column">
                    <label class="mb-3">Media Library</label>
                    <p>A complete list of the files located on your site</p>
                    <div class="tw-table-search-wrapper col-12">
                        <input type="search" class="form-control" v-model="querySearch" placeholder="Search news / notices" />
                        <span class="tw-table-header-search-icon"><i class="fas fa-search"></i></span>
                    </div>
                    <div class="col-12 text-end mt-2">
                        <label  for="media" class="d-flex align-items-center justify-content-end"><span class="btn border btn-default"><i class="far fa-image"></i> Add Media File</span></label>
                        <input type="file" class="d-none" ref="media" id="media" @change="handleFileUploads($event)"  :accept="onlyImageType && 'image/png, image/jpg, image/jpeg'" multiple>
                    </div>
                    <div class="table-responsive position-relative">
                        <div v-if="!requestResolved" class="tw-table-loading-overlay pt-5 d-flex align-items-start justify-content-center position-absolute top-0 bottom-0 start-0 end-0 bg-white opacity-75 tw-light-grey-bg-color" :class="!mediaLibrary.length && 'bg-transparent'">
                            <i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
                        </div>
                        <table ng-if="!vm.permissions.notices" st-table="vm.results" st-pipe="vm.loadResults" class="tw-table table text-nowrap">
                            <thead>
                                <tr>
                                    <th>File</th>
                                    <th>Name</th>
                                    <th st-sort="date">
                                        <span class="cursor-pointer d-flex align-items-center" @click="setOrder('date')">
                                            <span class="me-3">Date</span>
                                            <span v-if="orderBy != 'date'" class="d-inline-flex align-items-center justify-content-center">
                                                <span class="tw-toggle-angle-up me-2">
                                                    <span class="d-block"></span>
                                                </span>
                                                <span class="tw-toggle-angle-down">
                                                    <span class="d-block"></span>
                                                </span>
                                            </span>
                                            <span v-if="orderBy == 'date' && order == 'asc'" class="d-inline-flex align-items-center justify-content-center">
                                                <span class="tw-toggle-angle-up">
                                                    <span class="d-block"></span>
                                                </span>
                                            </span>
                                            <span v-if="orderBy == 'date' && order == 'desc'" class="d-inline-flex align-items-center justify-content-center">
                                                <span class="tw-toggle-angle-down">
                                                    <span class="d-block"></span>
                                                </span>
                                            </span>
                                        </span>
                                    </th>
                                    <th>Type</th>
                                </tr>
                            </thead>

                            <tbody class="position-relative">
                            <!-- <tbody v-if="posts && posts.length"> -->
                                <tr v-for="(media, index) in mediaLibrary" :class="[(media.type == 'tw_alerts' && 'red'), (media.selected && 'tw-media-lib-selected')]" :key="index" class="cursor-pointer" @click="addMediaToPost(index, $event)">
                                <!-- <tr v-for="(media, index) in mediaLibrary" :class="[(media.type == 'tw_alerts' && 'red'), (media.selected && 'tw-media-lib-selected')]" :key="index" class="cursor-pointer" @click="countSelected(index, $event)"> -->
                                    <td>
                                        <img v-if="media.media_type == 'image'" :src="(media.media_details.sizes && media.media_details.sizes.length) ? media.media_details.sizes.thumbnail.source_url : media.source_url" height="53" width="55">
                                        <span v-else  class="d-flex align-items-center">
                                            <span v-html="defaultImage"></span>
                                        </span>
                                    </td>
                                    <td><span v-html="media.filename"></span></td>
                                    <td>{{ moment(media.post_date || media.date).format('ll') }}</td>
                                    <td>{{media.media_type}}</td>
                                   
                                </tr>
                            </tbody>

                            <!-- <tbody v-if="!requestResolved && (!posts || !posts.length)">
                                <tr>
                                    <td colspan="6" class="text-center">Loading <i class="fa fa-spinner fa-spin"></i></td>
                                </tr>
                            </tbody> -->

                            <tbody v-if="requestResolved && (!mediaLibrary || !mediaLibrary.length)">
                                <tr>
                                    <td colspan="6" class="text-center">Nothing to show, no results found.</td>
                                </tr>
                            </tbody>

                            <tfoot v-if="mediaLibrary.length">


                               <!--  <tr>
                                    <td colspan="3">
                                        Rows per page
                                        <select v-model="perPage" class="border">
                                            <option :value="5">5</option>
                                            <option :value="10">10</option>
                                            <option :value="20">20</option>
                                        </select>
                                    </td>
                                    <td colspan="4" class="tw-table-pagination text-end">

                                        <Pagination
                                            :totalItems="numberOfPosts"
                                            :currentPage="page"
                                            :perPage="perPage"
                                            @pageChanged="setPage"
                                            :goButton="false"
                                            styled="centered"
                                            borderActiveColor=""
                                            borderTextActiveColor="text-white"
                                        />
                                    </td>
                                </tr> -->
                            </tfoot>
                        </table>
                    </div>
                    <div v-if="numberOfMedia" class="text-center mt-2">
                        <Pagination
                            :totalItems="numberOfMedia"
                            :currentPage="page"
                            :perPage="perPage"
                            @pageChanged="setPage"
                            :goButton="false"
                            styled="centered"
                            borderActiveColor=""
                            borderTextActiveColor="text-white"
                            :paginationDisabled="!requestResolved"
                        />
                    </div>
                    <div class="modal-footer text-end mt-auto">
                        <button v-if="allowMulti" class="btn tw-red-bg-color text-white" @click.prevent="insertMedia">Add</button>
                        <button class="btn tw-dark-grey-bg-color text-white" type="button" @click="mediaLibClose">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import wordpressMixin from '@/mixins/wordpressMixin';
    import cms6Mixin from '@/mixins/cms6Mixin';
    import Pagination from '@/components/pagination/Pagination';

    let site = localStorage.getObject('site');

    let mixins;

    if (site.type == 'wordpress') {
        mixins = [wordpressMixin];
    } else if(site.type == 'cms6') {
        mixins = [cms6Mixin];
    }

    export default {
        name: 'MediaLibbraryInModal',
        components: {
            Pagination
        },
        emits: ["mediaLibClose", "setMedia", "mediaInsert"],
        data: () => ({
            mediaLibrary: {},
            requestResolved: false,
            perPage: 6,
            page: 1,
            numberOfMedia: 0,
            numberOfPages: 0,
            querySearch: '',
            order: 'desc',
            orderBy: 'date',
            defaultImage: '<div class="tw-defaul-featured-img bg-white border"><i class="fas fa-file"></i></div>',
            selected: 0,
            requestingFile: false,
            allowMulti: false,
            onlyImageType: false,
            // mediaLibOpened: false,
        }),
        mounted() {

        },
        watch: {
            mediaLibOpened (val) {
                if (!val) {
                    this.selected = 0;
                    this.requestingFile = false;
                    this.allowMulti = false;
                    this.onlyImageType = false;
                }
            },
            page: function () {
                this.setMediaLibrary();
            },
            querySearch: function () {
                this.setMediaLibrary();
            },
            orderBy: function () {
                this.setMediaLibrary();
            },
            order: function () {
                this.setMediaLibrary();
            },
        },
        methods: {
            async setMediaLibrary() {
                this.requestResolved = false;
                let res = await this.getMediaLibrary(this.page, this.perPage, this.order, this.orderBy, this.querySearch, this.onlyImageType);

                if (res && res.status === 200) {
                    this.requestResolved = true;
                    this.mediaLibrary = res.data || [];
                    this.numberOfShowedMedia = this.mediaLibrary.length;
                    this.numberOfMedia = parseInt( res.headers['x-wp-total'] );
                    this.numberOfPages = parseInt( res.headers['x-wp-totalpages'] );

                    let self = this;

                    this.mediaLibrary.forEach(function(media,index){

                        self.mediaLibrary[index].filename = media.source_url.split('/').pop();
                    })
                }

            },
            async handleFileUploads (event) {
                // this.mediaLibrary = [];
                this.requestResolved = false;
                let files = Array.from(event.target.files);

                // console.log(Array.from(files))

                let self = this;

                await Promise.all(files.map( async function(file){

                    let res = await self.uploadFile(file);

                    if (res.data && res.data.type && res.data.type == 'error') {

                        let text = res.data.errors.file.join('<br>');

                        self.$swal({
                            toast: true,
                            showConfirmButton: true,
                            timer: 10000,
                            title: 'Invalid file!',
                            text: text,
                            position: "top-end",
                            icon: "error",
                            closeModal: true,
                            timerProgressBar: true,
                        });
                    }

                }));
                
                this.setMediaLibrary();
                this.$refs.media.value = '';
            },
            countSelected(index) {

                this.mediaLibrary[index].selected = !this.mediaLibrary[index].selected;

                this.selected = this.mediaLibrary.filter(function(media) {return media.selected}).length;
            },
            addMediaToPost(index) {
                if (!this.allowMulti) {
                    this.insertSingleMedia(index);
                } else {
                    this.countSelected(index);
                }
            },
            insertSingleMedia (index) {
                let self = this;
                this.mediaLibrary.map(function(media, index){
                    self.mediaLibrary[index].selected = false;
                })

                this.mediaLibrary[index].selected = true;

                if (this.requestingFile) {
                    this.returnFile(this.mediaLibrary[index]);
                } else {
                    this.insertMedia();
                }
            },
            insertMedia () {

                let content = '';

                this.mediaLibrary.map(function(media) {
                    if(media.selected){
                        if (media.media_type == 'image') {
                            content +=  '<img src="' + media.source_url + '"/>';
                        } else {
                            content += media.source_url;
                        }
                    }
                });
                // this.modal.hide();
                this.$emit('mediaInsert', content);
            },
            returnFile (file) {
                // console.log(file)
                // this.modal.hide();
                this.$emit('setMedia', file);
            },
            mediaLibOpen (value, requestingFile, allowMulti, onlyImageType = false) {
                if (value) {
                    this.requestingFile = requestingFile; // if not inserting into tinymce editor then it's this
                    this.allowMulti = allowMulti;
                    this.onlyImageType = onlyImageType;
                    this.setMediaLibrary();
                }
            },
            mediaLibClose () {
                this.$emit('mediaLibClose');
            },
            setPage(n) {
                this.page = n;
            },
            setOrder(orderBy) {
                if (this.orderBy != orderBy) {
                    this.order = 'desc';
                } else if (this.orderBy == orderBy && this.order == 'desc') {
                    this.order = 'asc';
                } else if (this.orderBy == orderBy && this.order == 'asc') {
                    this.order = 'desc';
                }
                this.orderBy = orderBy;
            },
        },
        props: {
            mediaLibOpened: Boolean,
        },
        mixins: mixins
    }
</script>
<style scoped>
    .collapsed {
      width: 0;
    }
    .anime {
        /*flex-shrink: 1;*/
        transition: all 300ms ease;
    }
    .tw-create-modal-media-lib-wrapper {
        border-left: 1px solid #E0E0E0;
        overflow: hidden;
    }
    table {
        border-collapse: separate;
        border-spacing: 0 2px;
    }
    thead, th {
        border-bottom: 1px solid #E0E0E0;
    }
    tr, td {
        border: none;
    }
    .tw-media-lib-selected {
        background: #fff;
        border-radius: 14px;
    }
    .tw-media-lib-selected td {
        border-top: 1px solid var(--tw-red);
        border-bottom: 1px solid var(--tw-red);
    }
    .tw-media-lib-selected td:first-child {
        border-left: 1px solid var(--tw-red);
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
    }
    .tw-media-lib-selected td:last-child {
        border-right: 1px solid var(--tw-red);
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
    }
</style>