// import { customPlugin } from '@/tinymce/plugins/mybuttonMenu/plugin.js';
import { cuPlugin } from '@/tinymce/plugins/myCustomToolbarButton/plugin.js';

const config = {
    height: 500,
    content_style: "body#tinymce { margin-bottom: 50px; }",
    menubar: false,
    // plugins: [
    //         'advlist autolink lists link image charmap print preview anchor',
    //         'searchreplace visualblocks code fullscreen',
    //         'insertdatetime media table paste code help wordcount'
    // ],
    plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            // 'insertdatetime media table paste code help wordcount contextmenu',
            'myCustomToolbarButton', 
            
    ],
    contextmenu: false, 
    // toolbar:
    // 'undo redo | formatselect | bold italic backcolor | \
    //     alignleft aligncenter alignright alignjustify | \
    //     bullist numlist outdent indent | removeformat | help'
    toolbar:
    'formatselect | bold italic underline strikethrough | \
        alignleft aligncenter alignright alignjustify | \
        numlist bullist outdent indent | forecolor backcolor | link myCustomToolbarButton media image | removeformat',
    setup: function () {

        // Here we can add plugin 
        window.tinymce.PluginManager.add('myCustomToolbarButton', cuPlugin);
        // window.tinymce.PluginManager.add('mybutton', customPlugin);

        
    }
}

export default { config };